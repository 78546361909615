<template>
  <div class="icon-library">
    <h1>{{ $t("icon_library") }}</h1>
    <p>{{ $t("icon_library_description") }}</p>
    <div class="box">
      <el-upload
        class="avatar-uploader"
        :on-success="handleSuccess"
        :action="`${origin}/api/v1/system/uploads`"
        :show-file-list="false"
      >
        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
        <!-- <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon> -->
        <div v-else class="img-content">
          <img class="img-icon" src="../../assets/icon-1.png" alt="" />
          <p>{{ $t("upload_image") }}</p>
          <p>{{ $t("image_format") }}</p>
        </div>
      </el-upload>
      <el-row :gutter="20">
        <el-col :xs="12" :md="4" v-for="icon in icons" :key="icon.id">
          <div class="inner-box">
            <img :src="icon.href" alt="" />
            <!-- <img src="../../assets/profile-photo@2x.png" alt="" /> -->
          </div>
        </el-col>
        <el-col :xs="12" :md="4" v-if="userImg">
          <div class="inner-box">
            <img :src="userImg" />
          </div>
        </el-col>
      </el-row>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="icons.length + 0"
      />
    </div>
    <el-button class="update" @click="update">{{
      $t("update_button")
    }}</el-button>
  </div>
</template>

<script>
import { ElNotification } from "element-plus";

export default {
  data() {
    return {
      imageUrl: "",
      userImg: "",
    };
  },
  computed: {
    selectedButtonDetails() {
      return this.$store.getters["profile/selectedButtonDetails"];
    },
    selectedButtonStyles() {
      return this.$store.getters["profile/selectedButtonStyles"];
    },
    selectedFontStyle() {
      return this.$store.getters["profile/selectedFontStyle"];
    },
    selectedBackgroundStyle() {
      return this.$store.getters["profile/selectedBackgroundStyle"];
    },
    selectedTheme() {
      return this.$store.getters["profile/selectedTheme"];
    },
    userDetails() {
      return this.users.find(
        (user) => user.username === this.$route.params.user
      );
    },
    users() {
      return this.$store.getters["profile/users"];
    },
    // userDetails() {
    //   return this.$store.getters["profile/userDetails"];
    // },
    icons() {
      return this.$store.getters["profile/icons"];
    },
    origin() {
      return window.location.origin;
    }
  },
  methods: {
    handleSuccess(response, uploadFile) {
      console.log(response);
      console.log(uploadFile);
      this.userImg = `/api/v1/system/uploads/${response.item.name}`;
      // const image = `/api/v1/system/uploads/${response.item.name}`;
      // this.$store
      //   .dispatch("auth/checkAccessToken")
      //   .then(() => {
      //     this.$store.dispatch("profile/addIcon", { href: image }).then(() => {
      //       this.$store.dispatch("profile/getIcons");
      //     });
      //   })
      //   .catch(() => {
      //     this.$store
      //       .dispatch("auth/checkRefreshToken")
      //       .then(() => {
      //         this.$store
      //           .dispatch("profile/addIcon", { href: image })
      //           .then(() => {
      //             this.$store.dispatch("profile/getIcons");
      //           });
      //       })
      //       .catch(() => {
      //         ElNotification({
      //           title: "Error",
      //           message: this.$t("token_expired"),
      //           type: "error",
      //         });
      //         this.$store.dispatch("auth/logout");
      //       });
      //   });
    },
    sendData(data) {
      this.$store
        .dispatch("auth/checkAccessToken")
        .then(() => {
          this.$store.dispatch("profile/updateTheme", data).then(() => {
            this.$store.dispatch("profile/getThemes", 1);
            this.$store.dispatch("profile/getUserDetails", this.userDetails.id);
            ElNotification({
              title: "Success",
              message: this.$t("theme_updated"),
              type: "success",
            });
            this.$router.push({ name: "styling" });
          });
        })
        .catch(() => {
          this.$store
            .dispatch("auth/checkRefreshToken")
            .then(() => {
              this.$store.dispatch("profile/updateTheme", data).then(() => {
                this.$store.dispatch("profile/getThemes", 1);
                this.$store.dispatch(
                  "profile/getUserDetails",
                  this.userDetails.id
                );

                ElNotification({
                  title: "Success",
                  message: this.$t("theme_updated"),
                  type: "success",
                });
                this.$router.push({ name: "styling" });
              });
            })
            .catch(() => {
              ElNotification({
                title: "Error",
                message: this.$t("token_expired"),
                type: "error",
              });
              this.$store.dispatch("auth/logout");
            });
        });
    },
    update() {
      const data = {
        account: this.userDetails.id,
        ...this.selectedButtonDetails,
        ...this.selectedButtonStyles,
        ...this.selectedFontStyle,
        ...this.selectedBackgroundStyle,
      };
      console.log(data);
      console.log(Object.keys(this.selectedButtonDetails).length);

      if (
        this.selectedTheme.id &&
        this.selectedTheme.id !== "Create_Your_Own"
      ) {
        this.sendData(this.selectedTheme);
        return;
      }

      if (this.selectedTheme.id !== "Create_Your_Own") {
        ElNotification({
          title: "Error",
          message: this.$t("select_create_your_own"),
          type: "error",
        });
        return;
      }

      if (
        Object.keys(this.selectedButtonDetails).length <= 0 ||
        Object.keys(this.selectedFontStyle).length <= 0 ||
        Object.keys(this.selectedBackgroundStyle).length <= 0
      ) {
        ElNotification({
          title: "Error",
          message: this.$t("update_theme_validation"),
          type: "error",
        });
        return;
      }
      this.sendData(data);
    },
  },
};
</script>

<style scoped>
.icon-library {
  margin-top: 2rem;
}

.icon-library h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: #262626;
}

.icon-library p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  margin-top: 0.5rem;
}

.icon-library .box {
  background: #f3f3f5;
  border: 1px solid #000000;
  border-radius: 24px;
  padding: 1rem;
  margin-top: 2rem;
}

.icon-library .img-content {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-library .img-icon {
  width: 2rem;
}

.icon-library :deep(.el-upload.el-upload--text) {
  width: 100%;
  height: 10rem;
}

.icon-library .img-content p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #202226;
}

.icon-library .img-content p:last-of-type {
  font-size: 14px;
  line-height: 20px;
  color: rgba(32, 34, 38, 0.4);
  margin-top: 0.5rem;
}

.icon-library .inner-box {
  background: #e9e9eb;
  border: 1px solid #262626;
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8rem;
  margin-bottom: 1rem;
}

.icon-library .inner-box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.icon-library .el-row {
  margin-top: 2rem;
}

.icon-library .el-row .el-col {
  margin-bottom: 1rem;
}

.icon-library .el-pagination {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.icon-library .el-pagination :deep(.number) {
  border: 1px solid #262626;
  border-radius: 12px;
}

.icon-library .el-pagination :deep(.number.is-active) {
  border: none;
}

.icon-library .el-button.update {
  background: #0093e9;
  border-radius: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  display: flex;
  margin-left: auto;
  padding: 1.5rem 3rem;
  margin-top: 2rem;
}

@media screen and (max-width: 767px) {
  .icon-library .inner-box img {
    height: 100%;
    object-fit: cover;
  }
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>