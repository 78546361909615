export default {
  userDetails(state) {
    return state.userDetails;
  },
  accountSocials(state) {
    return state.accountSocials;
  },
  buttons(state) {
    return state.buttons;
  },
  fonts(state) {
    return state.fonts;
  },
  selectedButtonDetails(state) {
    return state.selectedButtonDetails;
  },
  selectedButtonStyles(state) {
    return state.selectedButtonStyles;
  },
  selectedFontStyle(state) {
    return state.selectedFontStyle;
  },
  selectedBackgroundStyle(state) {
    return state.selectedBackgroundStyle;
  },
  icons(state) {
    return state.icons;
  },
  themes(state) {
    return state.themes;
  },
  selectedTheme(state) {
    return state.selectedTheme;
  },
  users(state) {
    return state.users;
  },
};
