import axios from "axios";
import i18n from "@/i18n";

export default {
  async getContents(context) {
    const response = await axios.get("/api/v1/cms/contents", {
      headers: {
        "accept-language": i18n.global.locale,
      },
    });
    context.commit("SET_CONTENTS", response.data.items);
  },
  async getLanguages(context) {
    const response = await axios.get("/api/v1/system/languages");
    context.commit("SET_LANGUAGES", response.data.items);
  },
};
