<template>
  <div class="card-dialog">
    <el-dialog :model-value="dialogVisible" title="Tips" width="30%">
      <p>{{ $t("no_account") }}</p>
      <img src="../../assets/card.png" alt="" />
      <el-button @click="signUp">{{ $t("sign_up") }}</el-button>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    signUp() {
      this.dialogVisible = false;
      // this.$router.replace("/");
      this.$store.commit("TOGGLE_DIALOG_VISIBLE", true);
      this.$store.commit("CHANGE_AUTH_OPTION", "register");
    },
  },
  created() {
    if (this.$route.query.dialog) this.dialogVisible = true;
  },
};
</script>

<style scoped>
.card-dialog :deep(.el-dialog) {
  background: #f3f3f5;
  border: 1px solid #000000;
  border-radius: 24px;
}

.card-dialog :deep(.el-dialog .el-dialog__header) {
  display: none;
}

.card-dialog p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #262626;
  margin-bottom: 1rem;
}

.card-dialog img {
  width: 21.5rem;
  margin: 0 auto;
  display: block;
}

.card-dialog .el-button {
  background: linear-gradient(
      83.9deg,
      #c3f7c4 1.51%,
      rgba(195, 247, 196, 0) 100%
    ),
    #0093e9;
  border-radius: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #262626;
  width: 100%;
  padding: 2rem 1rem;
}

@media screen and (max-width: 1330px) {
  .card-dialog img {
    width: 20rem;
  }
}
@media screen and (max-width: 1200px) {
  .card-dialog img {
    width: 18rem;
  }
}
@media screen and (max-width: 991px) {
  .card-dialog :deep(.el-dialog) {
    width: 90%;
  }
}
</style>