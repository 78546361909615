<template>
  <div class="button-styles">
    <el-row>
      <el-col :sm="24" :md="6">
        <p>{{ $t("button_size") }}</p>
      </el-col>
      <el-col :sm="24" :md="18">
        <el-slider @change="setValues" v-model="value1" :max="50" />
      </el-col>
      <el-col :sm="24" :md="6">
        <p>{{ $t("button_font_color") }}</p>
      </el-col>
      <el-col :xs="6" :md="2">
        <el-color-picker @change="setValues" v-model="color1" />
      </el-col>
      <el-col :xs="12" :md="16">
        <div class="color-box">
          <span>#</span>
          <p>{{ color1.replace("#", "") }}</p>
        </div>
      </el-col>
      <el-col :sm="24" :md="6">
        <p>{{ $t("button_color") }}</p>
      </el-col>
      <el-col :xs="6" :md="2">
        <el-color-picker @change="setValues" v-model="color2" />
      </el-col>
      <el-col :xs="12" :md="16">
        <div class="color-box">
          <span>#</span>
          <p>{{ color2.replace("#", "") }}</p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value1: 0,
      color1: "#0093E9",
      color2: "#0093E9",
    };
  },
  computed: {
    // userDetails() {
    //   return this.$store.getters["profile/userDetails"];
    // },
    userDetails() {
      return this.users.find(
        (user) => user.username === this.$route.params.user
      );
    },
    users() {
      return this.$store.getters["profile/users"];
    },
  },
  methods: {
    setValues() {
      const data = {
        buttonSize: this.value1,
        buttonTextColor: this.color1,
        buttonBackgroundColor: this.color2,
      };
      console.log(data);
      this.$store.commit("profile/SET_SELECTED_BUTTON_STYLES", data);
    },
  },
  created() {
    if ("theme" in this.userDetails.resources) {
      this.value1 = this.userDetails.resources.theme.buttonSize;
      this.color1 = this.userDetails.resources.theme.buttonTextColor;
      this.color2 = this.userDetails.resources.theme.buttonBackgroundColor;
    }
  },
};
</script>

<style scoped>
.button-styles p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 56px;
  color: #262626;
}

.button-styles .el-row {
  align-items: center;
}

.button-styles .el-col {
  margin-bottom: 1rem;
}

.button-styles :deep(.el-slider__runway) {
  height: 1rem;
  border-radius: 16px;
  background: rgba(0, 147, 233, 0.2);
}
.button-styles :deep(.el-slider__bar) {
  height: 100%;
  background-color: #0093e9;
  border-radius: 16px;
}

.button-styles :deep(.el-slider__button-wrapper) {
  height: 2.7rem;
}

.button-styles
  :deep(.el-slider__button.el-tooltip__trigger.el-tooltip__trigger) {
  border: 2px solid #ffffff;
  border-radius: 4px;
  /* border-radius: 0; */
}

.button-styles :deep(.el-icon.el-color-picker__icon.is-icon-arrow-down) {
  display: none;
}

.button-styles :deep(.el-color-picker.el-tooltip__trigger.el-tooltip__trigger) {
  border: 1px solid #000000;
  box-shadow: inset 0px 0px 0px 4px #ffffff;
  border-radius: 12px;
}

.button-styles :deep(.el-color-picker__trigger) {
  height: 3rem;
  width: 3rem;
  border-radius: 12px;
}

.button-styles .color-box {
  background: #f3f3f5;
  border: 1px solid #262626;
  border-radius: 12px;
  width: fit-content;
  display: flex;
  align-items: center;
  height: 3.2rem;
  padding: 0 1rem;
}

.button-styles .color-box span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: rgba(38, 38, 38, 0.6);
  margin-right: 0.3rem;
}
</style>