<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="background">
    <el-row :gutter="20">
      <el-col :sm="24" :md="8">
        <div
          class="inner-box"
          :class="{ 'is-selected': currentBackground === 'Flat_Color' }"
          @click="setOption('Flat_Color')"
        >
          <div class="color"></div>
          <p>{{ $t("flat_color") }}</p>
        </div>
      </el-col>
      <el-col :sm="24" :md="8">
        <div
          class="inner-box"
          :class="{ 'is-selected': currentBackground === 'Gradient_Color' }"
          @click="setOption('Gradient_Color')"
        >
          <div class="color"></div>
          <p>{{ $t("gradient_color") }}</p>
        </div>
      </el-col>
      <el-col :sm="24" :md="8">
        <div
          class="inner-box"
          :class="{ 'is-selected': currentBackground === 'Custom_Image' }"
          @click="setOption('Custom_Image')"
        >
          <div class="color">
            <img src="../../assets/icon.png" alt="" />
            <el-upload
              class="avatar-uploader"
              :action="`${origin}/api/v1/system/uploads`"
              :on-success="handleSuccess"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
            </el-upload>
          </div>
          <p>{{ $t("upload_image") }}</p>
        </div>
      </el-col>
      <!-- <el-col :span="8">
        <div
          class="inner-box"
          :class="{ 'is-selected': currentBackground === 'customImage' }"
          @click="setOption('customImage')"
        >
          <div class="color">
            <img src="../../assets/icon.png" alt="" />
          </div>
          <p>{{ $t("upload_image") }}</p>
        </div>
      </el-col> -->
    </el-row>
    <el-row>
      <el-col :sm="24" :md="6">
        <p>{{ $t("first_color") }}</p>
      </el-col>
      <el-col :xs="5" :md="2">
        <el-color-picker @change="changeColor" v-model="color1" />
      </el-col>
      <el-col :xs="14" :md="16">
        <div class="color-box">
          <span>#</span>
          <p>{{ color1.replace("#", "") }}</p>
        </div>
      </el-col>
      <el-col
        :xs="24"
        :md="6"
        v-if="selectedBackgroundStyle.backgroundMode !== 'Flat_Color'"
      >
        <p>{{ $t("second_color") }}</p>
      </el-col>
      <el-col
        :xs="5"
        :md="2"
        v-if="selectedBackgroundStyle.backgroundMode !== 'Flat_Color'"
      >
        <el-color-picker @change="changeColor" v-model="color2" />
      </el-col>
      <el-col
        :xs="14"
        :md="16"
        v-if="selectedBackgroundStyle.backgroundMode !== 'Flat_Color'"
      >
        <div class="color-box">
          <span>#</span>
          <p>{{ color2.replace("#", "") }}</p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      color1: "#0093E9",
      color2: "#0093E9",
      currentBackground: null,
      imageUrl: null,
      backgroundDetails: {},
    };
  },
  computed: {
    origin() {
      return window.location.origin;
    },
    // userDetails() {
    //   return this.$store.getters["profile/userDetails"];
    // },
    userDetails() {
      return this.users.find(
        (user) => user.username === this.$route.params.user
      );
    },
    users() {
      return this.$store.getters["profile/users"];
    },
    selectedBackgroundStyle() {
      return this.$store.getters["profile/selectedBackgroundStyle"];
    },
  },
  methods: {
    setOption(option) {
      this.currentBackground = option;

      this.backgroundDetails = {};

      const data = {
        backgroundMode: option,
        backgroundColor1: this.color1,
        backgroundColor2:
          this.selectedBackgroundStyle.backgroundMode === "Flat_Color"
            ? ""
            : this.color2,
        backgroundImage: "",
      };
      console.log(data);
      this.backgroundDetails = data;
      this.$store.commit(
        "profile/SET_SELECTED_BACKGROUND_STYLE",
        this.backgroundDetails
      );
    },
    handleSuccess(response, uploadFile) {
      console.log(response);
      console.log(uploadFile);
      this.imageUrl = `${this.origin}/api/v1/system/uploads/${response.item.name}`;
      // this.imageUrl = `https://one-tap-membership.dev.marvelconnect.com/${response.item.name}`;
      console.log(this.imageUrl);

      this.backgroundDetails.backgroundImage = this.imageUrl;
      console.log(this.backgroundDetails);
      this.$store.commit(
        "profile/SET_SELECTED_BACKGROUND_STYLE",
        this.backgroundDetails
      );
    },
    changeColor() {
      this.backgroundDetails.backgroundColor1 = this.color1;
      this.backgroundDetails.backgroundColor2 = this.color2;
      this.$store.commit(
        "profile/SET_SELECTED_BACKGROUND_STYLE",
        this.backgroundDetails
      );
    },
  },
  created() {
    if ("theme" in this.userDetails.resources) {
      this.currentBackground = this.userDetails.resources.theme.backgroundMode;
      this.color1 = this.userDetails.resources.theme.backgroundColor1;
      this.color2 = this.userDetails.resources.theme.backgroundColor2;
    }
  },
};
</script>

<style scoped>
.background {
  background: #f3f3f5;
  border: 1px solid #000000;
  border-radius: 24px;
  padding: 1rem;
  margin-top: 2rem;
}

.background .inner-box {
  border: 1px solid rgba(38, 38, 38, 0.2);
  border-radius: 12px;
  padding: 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
}
.background .inner-box.is-selected {
  background: rgba(0, 147, 233, 0.1);
  border: 2px solid #0093e9;
  border-radius: 12px;
}

.background .inner-box .color {
  background: #3d3d3d;
  border-radius: 8px;
  width: 100%;
  height: 16rem;
  position: relative;
}

.background .inner-box .color img {
  width: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.background :deep(.el-upload.el-upload--text) {
  border: none;
}

.background .inner-box p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #202226;
  text-align: center;
  margin-top: 1rem;
}

.background .el-col:nth-of-type(2) .inner-box .color {
  background: linear-gradient(180deg, #999999 0%, #3d3d3d 100%);
}
.background .el-col:nth-of-type(3) .inner-box .color {
  background: #4d4d4d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background :deep(.el-icon.el-color-picker__icon.is-icon-arrow-down) {
  display: none;
}

.background :deep(.el-color-picker.el-tooltip__trigger.el-tooltip__trigger) {
  border: 1px solid #000000;
  box-shadow: inset 0px 0px 0px 4px #ffffff;
  border-radius: 12px;
}

.background :deep(.el-color-picker__trigger) {
  height: 3rem;
  width: 3rem;
  border-radius: 12px;
}

.background .color-box {
  background: #f3f3f5;
  border: 1px solid #262626;
  border-radius: 12px;
  width: fit-content;
  display: flex;
  align-items: center;
  height: 3.2rem;
  padding: 0 1rem;
}

.background .color-box span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: rgba(38, 38, 38, 0.6);
  margin-right: 0.3rem;
}

.background p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 56px;
  color: #202226;
}

.background .el-row:nth-of-type(2) {
  margin-top: 2rem;
  align-items: center;
}

.background .el-row:nth-of-type(2) .el-col {
  margin-bottom: 1rem;
}
</style>