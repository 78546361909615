<template>
  <the-header v-if="$route.name !== 'user'"></the-header>
  <router-view></router-view>
  <!-- <the-footer v-if="$route.path !== '/home-user'"></the-footer> -->
</template>

<script>
// import { useDocumentVisibility } from "ahooks-vue";
// import { watch } from "vue";
import TheHeader from "./components/common/TheHeader.vue";
// import TheFooter from "./components/common/TheFooter.vue";

export default {
  components: {
    TheHeader,
    // TheFooter,
  },
  // setup() {
  //   const { isVisible, visibilityState } = useDocumentVisibility();

  //   watch(visibilityState, () => {
  //     console.log("current visible state =  " + visibilityState.value);
  //   });

  //   return { visibilityState, isVisible };
  // },
  watch: {
    $i18n: {
      deep: true,
      handler() {
        this.$store.dispatch("dashboard/getContents");
      },
    },
  },
  computed: {
    userDetails() {
      return this.$store.getters["profile/userDetails"];
    },
  },
  mounted() {
    // if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    // } else {
    // }
    // window.addEventListener("visibilitychange", (event) => {
    //   if (document.hidden) {
    //     event.preventDefault();
    //     const input = confirm("You will be logged out of the system");
    //     console.log(input);
    //     if (input) {
    //       console.log("done");
    //     }
    //     // Chrome requires returnValue to be set.
    //     // event.returnValue = "";
    //     console.log("hidden");

    //     // localStorage.removeItem("accessToken");
    //     // localStorage.removeItem("refreshToken");
    //   }
    // });
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.el-input__inner,
.el-textarea__inner {
  font-family: Poppins;
}
</style>
