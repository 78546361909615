import { createRouter, createWebHistory } from "vue-router";
// import store from "./store/store";
import route from "@/route";
import { ElNotification } from "element-plus";

import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Content from "./pages/Content";
import Home from "./pages/Home";
import HomeUser from "./pages/HomeUser";
import PrivacyPolicy from "./pages/PrivacyPolicy.vue";
import Profile from "./pages/Profile";
import Styling from "./pages/Styling";
import TermsAndConditions from "./pages/TermsAndConditions";
import UserProfile from "./pages/UserProfile";

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // return savedPosition;
      return {
        savedPosition,
        behavior: "smooth",
      };
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      component: Home,
      name: "home",
    },
    {
      path: "/:user",
      component: HomeUser,
      name: "user",
    },
    {
      path: "/about-us",
      component: AboutUs,
    },
    {
      path: "/contact-us",
      component: ContactUs,
    },
    {
      path: "/terms-and-conditions",
      component: TermsAndConditions,
    },
    {
      path: "/privacy-policy",
      component: PrivacyPolicy,
    },
    {
      path: "/:user",
      component: UserProfile,
      name: "user-profile",
      beforeEnter: (_, _2, next) => {
        if (localStorage.getItem("accessToken")) {
          next();
        } else {
          ElNotification({
            title: "Error",
            message: "Please Login First!",
            type: "error",
          });
          route.replace("/");
        }
      },
      children: [
        {
          path: "profile",
          component: Profile,
          name: "profile",
        },
        {
          path: "content",
          component: Content,
          name: "content",
        },
        {
          path: "styling",
          component: Styling,
          name: "styling",
        },
      ],
    },
  ],
});

export default router;
