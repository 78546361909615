<template>
  <header>
    <base-container>
      <el-row>
        <el-col :span="3">
          <img
            @click="$router.push('/')"
            class="logo"
            src="../../assets/brand-logo@2x.png"
            alt=""
          />
        </el-col>
        <el-col :span="21">
          <el-dropdown popper-class="language-dropdown">
            <span class="el-dropdown-link">
              {{ 
                $i18n.locale === "en-US" ?
                  "English" : $i18n.locale === "zh-Hans-HK" ?
                    "簡体中文" : "繁體中文"
              }}
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="setLocale('en-US')">
                  English
                </el-dropdown-item>
                <el-dropdown-item @click="setLocale('zh-Hant-HK')">
                  繁體中文
                </el-dropdown-item>
                <el-dropdown-item @click="setLocale('zh-Hans-HK')">
                  簡体中文
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-button v-if="!$route.params.user" @click="openDialog" round>{{
            $t("get_started")
          }}</el-button>
        </el-col>
      </el-row>
    </base-container>
    <auth-dialog
      :dialog-visible="dialogVisible"
      @closeDialog="dialogVisible = $event"
    ></auth-dialog>
    <card-dialog></card-dialog>
  </header>
</template>

<script>
import { ArrowDown } from "@element-plus/icons-vue";
import AuthDialog from "./AuthDialog.vue";
import CardDialog from "./CardDialog.vue";

export default {
  components: {
    ArrowDown,
    AuthDialog,
    CardDialog,
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
  methods: {
    openDialog() {
      this.$store.commit("auth/GET_USER_REFERRAL_CODE", null);
      this.$store.commit("TOGGLE_DIALOG_VISIBLE", true);
      // this.dialogVisible = true;
    },
    setLocale(lang) {
      this.$i18n.locale = lang;
    },
  },
};
</script>

<style scoped>
header {
  background: #f3f3f5;
}

img.logo {
  /* width: 3.5rem; */
  width: calc(3.5rem + 20px);
  cursor: pointer;
}

.el-row {
  align-items: center;
}

.el-col:nth-of-type(2) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.el-button.is-round {
  margin-left: 1rem;
  background: #262626;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
  padding: 1.2rem 1.5rem;
}

.el-dropdown {
  cursor: pointer;
}

.el-dropdown-link.el-tooltip__trigger.el-tooltip__trigger {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #262626;
}
</style>

<style>
.language-dropdown .el-dropdown-menu__item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #262626;
}
</style>