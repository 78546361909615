<template>
  <link rel="stylesheet" type="text/css" :href="userStyle" v-if="isDisplayed" />

  <div class="create-your-own">
    <el-row :gutter="20">
      <el-col :sm="12" :md="6">
        <div
          class="single-style"
          :class="{ 'is-selected': currentTheme === 'Create_Your_Own' }"
          @click="setTheme({ id: 'Create_Your_Own' })"
        >
          <div class="inner-style">
            <p style="width: 13rem">{{ $t("create_your_own") }}</p>
          </div>
        </div>
      </el-col>
      <el-col :sm="12" :md="6" v-for="theme in sharedThemes" :key="theme.id">
        <div
          class="single-style"
          :class="{ 'is-selected': currentTheme === theme.id }"
          @click="setTheme(theme)"
        >
          <div :class="`theme-${theme.id}`" class="theme">
            <div class="inner-style" :class="`bg-${theme.id}`">
              <div class="content">
                <img src="../../assets/avatar-default.png" alt="" />
                <div class="btn"></div>
                <div class="btn"></div>
                <div class="btn"></div>
              </div>
            </div>
          </div>
          <!-- <div class="bg">
            <div class="inner-style" :class="`bg-${theme.id}`">
              <div class="content">
                <img src="../../assets/avatar-default.png" alt="" />
                <div class="btn"></div>
                <div class="btn"></div>
                <div class="btn"></div>
              </div>
            </div>
          </div> -->
        </div>
      </el-col>
    </el-row>
    <el-pagination
      @current-change="pageChanged"
      background
      layout="prev, pager, next"
      :total="themes.count + 0"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentTheme: null,
      isDisplayed: true,
    };
  },
  watch: {
    themes: {
      deep: true,
      handler() {
        this.forceRender();
      },
    },
  },
  computed: {
    themes() {
      return this.$store.getters["profile/themes"];
    },
    sharedThemes() {
      // if ("theme" in this.userDetails.resources) {
      //   return this.themes.items.filter(
      //     (item) => item.id !== this.userDetails.resources.theme.id
      //   );
      // }
      return this.themes.items;
    },
    users() {
      return this.$store.getters["profile/users"];
    },
    userDetails() {
      return this.$store.getters["profile/userDetails"];
    },
    userStyle() {
      return `/api/v1/libraries/styles.css`;
    },
    // userStyle() {
    //   return `/api/v1/accounts/${this.userDetails.item.username}/styles.css`;
    // },
  },
  methods: {
    setTheme(option) {
      console.log(option);
      this.currentTheme = option.id;
      this.$store.commit("profile/SET_SELECTED_THEME", option);
    },
    async forceRender() {
      this.isDisplayed = false;
      await this.$nextTick();
      this.isDisplayed = true;
    },
    pageChanged(num) {
      this.$store.dispatch("profile/getThemes", num);
      console.log(num);
    },
  },
};
</script>

<style scoped>
.create-your-own {
  background: #f3f3f5;
  border: 1px solid #262626;
  border-radius: 24px;
  padding: 1rem;
  margin-top: 2rem;
}

.create-your-own .bg,
.create-your-own .theme {
  height: 100%;
  border-radius: 16px;
}

.create-your-own .el-row .el-col {
  margin-bottom: 1rem;
}

.create-your-own .single-style {
  border: 1px solid rgba(38, 38, 38, 0.2);
  border-radius: 12px;
  padding: 1rem;
  height: 20rem;
  width: 100%;
  cursor: pointer;
}

.create-your-own .single-style.is-selected {
  background: rgba(0, 147, 233, 0.1);
  border: 2px solid #0093e9;
  border-radius: 12px;
}

.create-your-own .el-col .single-style .inner-style {
  /* background: #ffffff; */
  border: 1px dashed #333333;
  border-radius: inherit;
  padding: 1rem;
  height: 100%;
}

/* .create-your-own .el-col:nth-of-type(2) .single-style .inner-style {
  background: #ffffff;
  border: none;
} */

.create-your-own .single-style .inner-style:first-of-type {
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-your-own .single-style .inner-style p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #262626;
}

.create-your-own .single-style .inner-style .content img {
  width: 4rem;
  margin: 0.5rem auto;
  display: block;
  margin-bottom: 1rem;
}

.btn {
  /* background: #ffffff; */
  border: 1px solid #cccccc;
  border-radius: 80px;
  width: 10rem;
  height: 2rem;
  margin-bottom: 1rem;
  background-color: transparent;
}
/* .create-your-own .single-style .inner-style .content .btn {
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 80px;
  width: 10rem;
  height: 2rem;
  margin-bottom: 1rem;
  background-color: transparent;
} */

.create-your-own .el-pagination {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.create-your-own .el-pagination :deep(.number) {
  border: 1px solid #262626;
  border-radius: 12px;
}

.create-your-own .el-pagination :deep(.number.is-active) {
  border: none;
}

.create-your-own :deep(.el-pager) {
  /* width: 100%; */
  overflow: hidden;
}

@media screen and (max-width: 1390px) {
  .create-your-own .single-style .inner-style .content .btn {
    width: 12rem;
  }
}
</style>