<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="fonts">
    <h1>{{ $t("font") }}</h1>
    <el-row :gutter="20">
      <el-col :sm="8" :md="4" v-for="font in fonts" :key="font.id">
        <div
          class="single-font"
          :class="{ 'is-selected': currentFont === font.id }"
          @click="setOption(font)"
        >
          <p>{{ font.name }}</p>
        </div>
      </el-col>
      <!-- <el-col :span="4">
        <div class="single-font">
          <p>Carter One</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="single-font">
          <p>Concert One</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="single-font">
          <p>Dancing Script</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="single-font">
          <p>Poiret One</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="single-font">
          <p>Poppins</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="single-font">
          <p>PT Mono</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="single-font">
          <p>Rajdhani</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="single-font">
          <p>Roboto Slab</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="single-font">
          <p>Solway</p>
        </div>
      </el-col> -->
    </el-row>
    <el-row>
      <el-col :sm="24" :md="6">
        <p>{{ $t("font_color") }}</p>
      </el-col>
      <el-col :xs="5" :md="2">
        <el-color-picker @change="colorChange" v-model="color2" />
      </el-col>
      <el-col :xs="14" :md="16">
        <div class="color-box">
          <span>#</span>
          <p>{{ color2.replace("#", "") }}</p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      color2: "#0093E9",
      currentFont: null,
      fontDetails: {},
    };
  },
  computed: {
    fonts() {
      return this.$store.getters["profile/fonts"];
    },
    // userDetails() {
    //   return this.$store.getters["profile/userDetails"];
    // },
    userDetails() {
      return this.users.find(
        (user) => user.username === this.$route.params.user
      );
    },
    users() {
      return this.$store.getters["profile/users"];
    },
  },
  methods: {
    setOption(item) {
      this.currentFont = item.id;
      console.log(item);
      this.fontDetails = {};

      const data = {
        font: item.id,
        fontFamily: item.fontFamily,
        fontHref: item.href,
        fontTextColor: this.color2,
      };

      this.fontDetails = data;
      console.log(this.fontDetails);
      this.$store.commit("profile/SET_SELECTED_FONT_STYLE", this.fontDetails);
    },
    colorChange() {
      this.fontDetails.fontTextColor = this.color2;
      console.log(this.fontDetails);
      this.$store.commit("profile/SET_SELECTED_FONT_STYLE", this.fontDetails);
    },
  },
  created() {
    if ("theme" in this.userDetails.resources) {
      this.currentFont = this.userDetails.resources.theme.font;
      this.color2 = this.userDetails.resources.theme.fontTextColor;
    }
  },
};
</script>

<style scoped>
.fonts {
  background: #f3f3f5;
  border: 1px solid #000000;
  border-radius: 24px;
  padding: 1rem;
  margin-top: 2rem;
}

.fonts h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
  margin-bottom: 1rem;
}

.fonts .el-col {
  margin-bottom: 1rem;
}

.fonts .single-font {
  background: #f3f3f5;
  border: 1px solid rgba(38, 38, 38, 0.2);
  border-radius: 12px;
  padding: 1rem 0.5rem;
  cursor: pointer;
}

.fonts .single-font.is-selected {
  background: rgba(0, 147, 233, 0.1);
  border: 2px solid #0093e9;
  border-radius: 12px;
}
.fonts .single-font p {
  font-family: "Carter One";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #262626;
  text-align: center;
}

.fonts .el-col:nth-of-type(2) .single-font p {
  font-family: "Concert One";
}
.fonts .el-col:nth-of-type(3) .single-font p {
  font-family: "Dancing Script";
}
.fonts .el-col:nth-of-type(4) .single-font p {
  font-family: "Poiret One";
}
.fonts .el-col:nth-of-type(5) .single-font p {
  font-family: "Poppins";
}
.fonts .el-col:nth-of-type(6) .single-font p {
  font-family: "PT Mono";
}
.fonts .el-col:nth-of-type(7) .single-font p {
  font-family: "Rajdhani";
}
.fonts .el-col:nth-of-type(7) .single-font p {
  font-family: "Roboto Slab";
}
.fonts .el-col:nth-of-type(7) .single-font p {
  font-family: "Solway";
}

.fonts .color-box {
  background: #f3f3f5;
  border: 1px solid #262626;
  border-radius: 12px;
  width: fit-content;
  display: flex;
  align-items: center;
  height: 3.2rem;
  padding: 0 1rem;
}

.fonts .color-box span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: rgba(38, 38, 38, 0.6);
  margin-right: 0.3rem;
}

.fonts :deep(.el-color-picker.el-tooltip__trigger.el-tooltip__trigger) {
  border: 1px solid #000000;
  box-shadow: inset 0px 0px 0px 4px #ffffff;
  border-radius: 12px;
}

.fonts :deep(.el-color-picker__trigger) {
  height: 3rem;
  width: 3rem;
  border-radius: 12px;
}

.fonts :deep(.el-icon.el-color-picker__icon.is-icon-arrow-down) {
  display: none;
}

.fonts .el-row:nth-of-type(2) {
  align-items: center;
}

.fonts p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 56px;
  color: #202226;
}
</style>