<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="share">
    <img src="../../assets/Frame-190.png" alt="" />
    <div class="text">
      <p>{{ $t("share_your_theme") }}</p>
      <p>{{ $t("share_description") }}</p>
    </div>
    <el-button @click="popup">{{ $t("share") }}</el-button>
  </div>
</template>

<script>
import { ElNotification, ElMessage, ElMessageBox } from "element-plus";
export default {
  computed: {
    selectedButtonDetails() {
      return this.$store.getters["profile/selectedButtonDetails"];
    },
    selectedButtonStyles() {
      return this.$store.getters["profile/selectedButtonStyles"];
    },
    selectedFontStyle() {
      return this.$store.getters["profile/selectedFontStyle"];
    },
    selectedBackgroundStyle() {
      return this.$store.getters["profile/selectedBackgroundStyle"];
    },
    // userDetails() {
    //   return this.$store.getters["profile/userDetails"];
    // },
    userDetails() {
      return this.users.find(
        (user) => user.username === this.$route.params.user
      );
    },
    users() {
      return this.$store.getters["profile/users"];
    },
    selectedTheme() {
      return this.$store.getters["profile/selectedTheme"];
    },
  },
  methods: {
    popup() {
      ElMessageBox.confirm(this.$t("confirm_text"), "Warning", {
        confirmButtonText: this.$t("confirm_button"),
        cancelButtonText: this.$t("cancel_button"),
        type: "warning",
        customClass: "share-popup",
      })
        .then(() => {
          this.share();
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: this.$t("share_canceled"),
          });
        });

      // ElMessageBox.alert(this.$t("confirm_text"), "Title", {
      //   // if you want to disable its autofocus
      //   // autofocus: false,
      //   confirmButtonText: this.$t("confirm_button"),
      //   callback: (action) => {
      //     if (action) {
      //       this.share();
      //       ElMessage({
      //         type: "info",
      //         message: this.$t("theme_shared"),
      //       });
      //     }
      //   },
      // });
    },
    share() {
      const data = {
        account: this.userDetails.id,
        ...this.selectedButtonDetails,
        ...this.selectedButtonStyles,
        ...this.selectedFontStyle,
        ...this.selectedBackgroundStyle,
      };
      console.log(data);
      console.log("theme" in this.userDetails.resources);
      // if (Object.keys(this.selectedTheme).length <= 0) {
      //   ElNotification({
      //     title: "Error",
      //     message: this.$t("select_theme_to_share"),
      //     type: "error",
      //   });
      //   return;
      // }
      if ("theme" in this.userDetails.resources) {
        this.$store
          .dispatch("auth/checkAccessToken")
          .then(() => {
            this.$store
              .dispatch("profile/share", this.userDetails.resources.theme)
              .then(() => {
                this.$store.dispatch("profile/getThemes");

                ElNotification({
                  title: "Success",
                  message: this.$t("theme_shared"),
                  type: "success",
                });
                this.$router.push({ name: "styling" });
              });
          })
          .catch(() => {
            this.$store
              .dispatch("auth/checkRefreshToken")
              .then(() => {
                this.$store
                  .dispatch("profile/share", this.userDetails.resources.theme)
                  .then(() => {
                    this.$store.dispatch("profile/getThemes");

                    ElNotification({
                      title: "Success",
                      message: this.$t("theme_shared"),
                      type: "success",
                    });
                    this.$router.push({ name: "styling" });
                  });
              })
              .catch(() => {
                ElNotification({
                  title: "Error",
                  message: this.$t("token_expired"),
                  type: "error",
                });
                this.$store.dispatch("auth/logout");
              });
          });
      } else {
        ElNotification({
          title: "Error",
          message: this.$t("create_theme_to_share"),
          type: "error",
        });
        return;
      }
    },
  },
};
</script>

<style scoped>
.share {
  background: #0093e9;
  border-radius: 24px;
  padding: 1rem 1rem 1rem 11rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 2rem;
  /* overflow: hidden; */
}

.share .el-button {
  background: #ffffff;
  border-radius: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0093e9;
  padding: 1.4rem;
}

.share img {
  width: 7.5rem;
  position: absolute;
  bottom: 0;
  left: 2rem;
}

.share .text p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.share .text p:last-of-type {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 0.3rem;
}

@media screen and (max-width: 720px) {
  .share {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 10.5rem;
  }

  .share .el-button {
    margin-top: 1rem;
  }
}
</style>

<style>
.el-message-box.share-popup,
.el-message-box.share-popup .el-message-box__btns .el-button {
  font-family: "Poppins";
}
</style>