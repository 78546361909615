<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="home-user-content">
    <template v-if="Object.keys(userDetails.resources).length > 0">
      <template v-if="userDetails.resources.content.length > 0">
        <div class="collapse btn" @click="exportData">
          <img src="../../assets/share.png" alt="" />
          <p>Save to contacts</p>
          <span></span>
        </div>
        <template v-for="item in userDetails.resources.content" :key="item.id">
          <el-collapse
            class="btn"
            v-model="activeName"
            accordion
            v-if="
              item.format === 'Video' ||
              item.format === 'Photo' ||
              item.format === 'Article'
            "
          >
            <el-collapse-item :name="item.id">
              <template #title>
                <img class="video-icon" :src="item.icon" alt="" />
                <p>{{ item.title }}</p>
              </template>
              <iframe
                v-if="item.format === 'Video'"
                :src="convertLink(item.content)"
                name="youtube embed"
                allow="autoplay; encrypted-media"
              >
              </iframe>
              <img
                class="collapse-img"
                v-if="item.format === 'Photo'"
                :src="item.content"
                alt=""
              />
              <p class="article" v-if="item.format === 'Article'">
                {{ item.content }}
              </p>
            </el-collapse-item>
            <!-- <el-collapse-item name="2">
          <template #title>
            <img
              class="video-icon"
              src="../../assets/display-video@2x.png"
              alt=""
            />
            <p>Zanzibar 2020, Tanzania</p>
          </template>
          <iframe src="https://www.youtube.com/embed/tgbNymZ7vqY"> </iframe>
        </el-collapse-item> -->
            <!-- <el-collapse-item name="3">
          <template #title>
            <img
              class="video-icon"
              src="../../assets/display-video@2x.png"
              alt=""
            />
            <p>10 Best Places to Visit in Canada</p>
          </template>
          <iframe src="https://www.youtube.com/embed/tgbNymZ7vqY"> </iframe>
        </el-collapse-item> -->
          </el-collapse>

          <!-- <a target="_blank" :href="item.content">
            <div class="collapse btn" v-if="item.format === 'Photo'">
              <img :src="item.icon" alt="" />
              <p>{{ item.title }}</p>
              <span></span>
            </div>
          </a> -->
          <!-- <div class="collapse">
        <img src="../../assets/display-photo@2x.png" alt="" />
        <p>Travel photo - part 2</p>
      </div> -->
          <a target="_blank" :href="item.content">
            <div class="collapse btn" v-if="item.format === 'Link'">
              <img :src="item.icon" alt="" />
              <!-- <img src="../../assets/display-link@2x.png" alt="" /> -->
              <p>{{ item.title }}</p>
              <span></span>
            </div>
          </a>
          <!-- <div class="collapse">
          <img src="../../assets/display-link@2x.png" alt="" />
          <p>Purchase Your One Tap DBC Now!</p>
        </div> -->
        </template>
      </template>
    </template>
    <div class="logo-content">
      <img
        @click="
          $router.push({
            name: 'profile',
            params: { user: currentUser.username },
          })
        "
        src="../../assets/Logo.png"
        class="logo"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: [],
    };
  },
  computed: {
    userDetails() {
      return this.$store.getters["profile/userDetails"];
    },
    origin() {
      return window.location.origin;
    },
    currentUser() {
      return this.$store.getters["auth/currentUserDetails"];
    }
  },
  methods: {
    // convertLink(item) {
    //   const linkId = item.substring(item.indexOf("=") + 1);
    //   const url = item.substring(0, item.indexOf("watch"));
    //   console.log(item);
    //   console.log(item.includes("watch"));
    //   console.log(`${url}embed/${linkId}`);
    //   return `${url}embed/${linkId}`;
    // },
    convertLink(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      console.log(match[2]);

      // return match && match[2].length === 11 ? match[2] : null;
      return `https://www.youtube.com/embed/${match[2]}`;
    },
    exportData() {
      window.location.href = `${this.origin}/api/v1/accounts/${this.userDetails.item.username}/card.vcf`;
    },
  },
  created() {
    this.userDetails.resources.content.forEach((item) => {
      this.activeName.push(item.id);
    });
  },
};
</script>

<style scoped>
.btn {
  padding: 0;
  padding: 0.3rem;
  margin-bottom: 1rem;
}
.home-user-content {
  width: 100%;
}

.home-user-content .el-collapse {
  /* box-shadow: 0px 0.5px 1px rgba(60, 119, 104, 0.4),
    0px 4px 8px rgba(60, 119, 104, 0.24); */
  border-radius: 24px;
  /* border: none; */
  /* background-color: transparent; */
  /* font-size: inherit !important; */
}

.home-user-content .el-collapse :deep(.el-collapse-item__header) {
  height: 33px !important;
}

.home-user-content .el-collapse.btn {
  padding: 0;
  background: transparent;
  border-image-repeat: stretch;
  /* min-height: 3.5rem; */
}

.home-user-content .el-collapse :deep(.el-collapse-item) {
  /* margin-bottom: 1rem; */
}

.home-user-content .el-collapse :deep(.el-collapse-item__arrow) {
  margin: 0;
  width: 1rem;
  height: 1rem;
}

.home-user-content .el-collapse :deep(.el-collapse-item__header) {
  border-radius: 24px;
  /* box-shadow: 0px 0.5px 1px rgba(60, 119, 104, 0.4),
    0px 4px 8px rgba(60, 119, 104, 0.24); */
  padding: 1rem;
  justify-content: space-between;
  border: none;
  background: none;
  color: inherit;
  font-size: inherit !important;
  min-height: 3.5rem;
  /* height: inherit; */
}

.home-user-content .el-collapse :deep(.el-collapse-item__header p) {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 400;
  /* font-size: 14px; */
  font-size: inherit !important;
  line-height: 20px;
  /* color: #202226; */
}
.home-user-content .el-collapse :deep(.el-icon svg) {
  margin: 0;
  width: 1rem;
  height: 1rem;
}

.home-user-content .el-collapse :deep(.el-collapse-item__content) {
  padding: 0;
  border-radius: 24px;
  background: transparent;
  color: inherit;
}

.home-user-content .el-collapse :deep(.el-collapse-item__wrap) {
  /* border-radius: 24px; */
  background: transparent;
  padding: 0;
  border: none;
}

.home-user-content img.video-icon {
  width: 1.7rem;
}

.home-user-content iframe {
  width: 100%;
  height: 18rem;
}

.home-user-content a {
  text-decoration: none;
}
.home-user-content .collapse {
  /* background: #ffffff; */
  /* box-shadow: 0px 0.5px 1px rgba(60, 119, 104, 0.4),
    0px 4px 8px rgba(60, 119, 104, 0.24); */
  border-radius: 24px;
  width: 100%;
  height: 3.58rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  margin-bottom: 1rem;
  background-color: transparent;
  cursor: pointer;
}

.home-user-content .collapse img {
  width: 1.7rem;
}

.home-user-content .collapse p {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 400;
  /* font-size: 14px; */
  font-size: inherit;

  line-height: 20px;
  /* color: #202226; */
  /* text-align: center; */
  /* width: 100%; */
  /* margin-left: -1.95rem; */
}

.home-user-content .collapse span {
  width: 1rem;
  height: 1rem;
}

.home-user-content .logo-content {
  background: #fff;
  border-radius: 100%;
  width: fit-content;
  padding: 1rem;
  margin: 0 auto;
  margin-top: 3rem;
  width: 6rem;
  height: 6rem;
}

.home-user-content img.logo {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.home-user-content .collapse-img {
  width: 100%;
  max-height: 280px;
  object-fit: cover;
}

p.article {
  padding: 0 1.3rem;
}
</style>