export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "one_tap_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["one tap digital business card"])},
  "protect_our_planet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protecting Our Planet Starts with You"])},
  "one_tap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One tap digital business card link to all your social media, video, article, website, contents and new releases"])},
  "get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Started"])},
  "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])},
  "easy_to_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy to use"])},
  "easy_to_use_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add social media, video, article, website, contents and new releases"])},
  "social_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to your socials"])},
  "social_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your followers can find you on other platforms"])},
  "web_based": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web based"])},
  "web_based_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No need to install anything"])},
  "protecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protecting"])},
  "our_planet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Planet"])},
  "starts_with_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starts with You"])},
  "error.account.invalid-username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Username!"])},
  "error.account.invalid-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Password!"])},
  "products_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have questions about our products and services?"])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "save_the_planet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save the Planet"])},
  "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
  "terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
  "about_the_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introducing the One Tap Digital Business Card, a game-changing solution for modern professionals seeking seamless networking. This innovative, user-friendly tool replaces traditional paper-based business cards, providing a high-tech and environmentally friendly alternative. With just a simple tap, you can effortlessly exchange contact information and cultivate meaningful connections. By embracing digital connectivity and eliminating the need for physical cards, this groundbreaking solution streamlines networking while contributing to a greener future. Upgrade your networking capabilities and make lasting impressions with the One Tap Digital Business Card."])},
  "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright © 2022 One Tap International Limited. All Rights Reserved"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "login_to_one_tap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to your One Tap account"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
  "remember_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
  "dont_have_an_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don’t have an account?"])},
  "username_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username is required!"])},
  "password_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is required!"])},
  "enter_username_and_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your username and e-mail address"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "create_acccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your account"])},
  "enter_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your information below"])},
  "display_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display name"])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
  "terms_and_conditions_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By creating an account you are agreeing to our"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "enter_password_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the password again."])},
  "passwords_do_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match!"])},
  "name_is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required!"])},
  "email_is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is required!"])},
  "enter_valid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email."])},
  "password_constraint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be atleast 8 characters, and contain at least one uppercase letter, one lowercase letter, one number, and one special character!"])},
  "personal_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
  "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Type"])},
  "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
  "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
  "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar"])},
  "choose_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose photo"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "update_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
  "change_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
  "phone_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone is required!"])},
  "token_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token expired! Please login again."])},
  "information_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information updated!"])},
  "new_password_is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password is required!"])},
  "enter_new_password_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the new password again."])},
  "old_password_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password is required!"])},
  "password_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password has been updated"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
  "styling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
  "social_profile_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social profile links"])},
  "phone_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your phone number"])},
  "views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Views"])},
  "whatsapp_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-written Whatsapp message"])},
  "add_new_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new content"])},
  "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
  "add_link_to_one_tap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add link to your One tap"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
  "add_photo_to_one_Tap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add photo to your One tap"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
  "add_video_to_one_Tap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add videos to your One tap"])},
  "upload_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to upload"])},
  "theme_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme Library"])},
  "create_your_own": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREATE YOUR OWN"])},
  "custom_theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Theme"])},
  "customize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choosing your customize the Button, Colors, Font & Background"])},
  "buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buttons"])},
  "button_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button Size"])},
  "button_font_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button Font Color"])},
  "button_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button Color"])},
  "font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font"])},
  "font_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font Color"])},
  "flat_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flat Color"])},
  "gradient_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gradient Color"])},
  "upload_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Image"])},
  "content_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Added!"])},
  "first_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Color"])},
  "second_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Color"])},
  "share_your_theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share Your Theme"])},
  "share_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your custome theme to public Library"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
  "icon_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon Library"])},
  "icon_library_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the Social Media, Social Links, or other website links icon"])},
  "image_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["( PNG/GIF format)"])},
  "my_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MY LINK"])},
  "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not yet registered an account"])},
  "theme_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme Updated!"])},
  "icon_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon Updated!"])},
  "url_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Url Copied!"])},
  "update_theme_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select button, font, and background styles!"])},
  "select_theme_to_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a theme to share!"])},
  "theme_shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme Shared!"])},
  "create_theme_to_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please create a theme to share!"])},
  "select_create_your_own": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select create your own theme from the theme library first!"])},
  "theme_library_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize your Buttons, Colors, Fonts & Background"])},
  "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press Confirm Button to Share"])},
  "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "share_canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share Canceled"])},
  "article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article"])},
  "article_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add article to your One tap"])},
  "fill_all_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Fill All Fields"])},
  "reset_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Token"])},
  "reset_token_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset token is required"])},
  "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])}
}