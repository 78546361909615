<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <Main />
</template>

<script>
import Main from "@/components/home/Main.vue";

export default {
  components: {
    Main,
  },
  // watch: {
  //   $i18n: {
  //     deep: true,
  //     handler() {
  //       this.$store.dispatch("dashboard/getContents");
  //     },
  //   },
  // },
  created() {
    this.$store.commit("TOGGLE_DIALOG_VISIBLE", false);
    this.$store.dispatch("dashboard/getContents");
    this.$store.dispatch("dashboard/getLanguages");
    this.$store.commit("profile/RESET_CUSTOM_THEME");
    this.$store.commit("profile/TOGGLE_THEME", true);
  },
};
</script>