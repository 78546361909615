<template>
  <div class="new-password">
    <div class="return" @click="backToLogin">
      <img src="../assets/back@2x.png" alt="" />
      <p class="back">{{ $t("back") }}</p>
    </div>
    <!-- <p class="create-account">{{ $t("forgot_password") }}</p>
      <p class="info">
        {{ $t("enter_username_and_email") }}
      </p> -->
    <el-form :rules="rules" :model="ruleForm" ref="ruleFormRef">
      <el-form-item prop="newPassword">
        <p class="label-username">{{ $t("new_password") }}</p>
        <el-input
          type="password"
          show-password
          v-model="ruleForm.newPassword"
          @blur="
            unFocused({ label: '.label-username', input: ruleForm.newPassword })
          "
          @focus="focus('.label-username')"
        ></el-input>
      </el-form-item>
      <el-form-item prop="confirmNewPassword">
        <p class="label-email">{{ $t("confirm_new_password") }}</p>
        <el-input
          type="password"
          show-password
          v-model="ruleForm.confirmNewPassword"
          @blur="
            unFocused({
              label: '.label-email',
              input: ruleForm.confirmNewPassword,
            })
          "
          @focus="focus('.label-email')"
        ></el-input>
      </el-form-item>
      <el-form-item prop="resetToken">
        <p class="label-reset-token">{{ $t("reset_token") }}</p>
        <el-input
          type="password"
          v-model="ruleForm.resetToken"
          @blur="
            unFocused({
              label: '.label-reset-token',
              input: ruleForm.resetToken,
            })
          "
          @focus="focus('.label-reset-token')"
        ></el-input>
      </el-form-item>
      <el-button @click="resetPassword">{{ $t("reset_password") }}</el-button>
    </el-form>
  </div>
</template>
    
    <script>
import { ElNotification } from "element-plus";

export default {
  props: ["username"],
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("password_required")));
      } else {
        if (this.ruleForm.confirmNewPassword !== "") {
          if (!this.$refs.ruleFormRef) return;
          this.$refs.ruleFormRef.validateField(
            "confirmNewPassword",
            () => null
          );
        }
        callback();
      }
    };
    const validateConfirmPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("enter_password_again")));
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error(this.$t("passwords_do_not_match")));
      } else {
        callback();
      }
    };

    return {
      ruleForm: {
        newPassword: "",
        confirmNewPassword: "",
      },
      rules: {
        newPassword: [{ validator: validatePass, trigger: "blur" }],
        confirmNewPassword: [
          { validator: validateConfirmPass, trigger: "blur" },
        ],
        resetToken: {
          required: true,
          message: this.$t("reset_token_required"),
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    focus(item) {
      const el = document.querySelector(item);
      el.style.top = "20%";
    },
    unFocused({ label, input }) {
      const el = document.querySelector(label);
      if (input) {
        return;
      }
      el.style.top = "50%";
    },
    backToLogin() {
      this.$store.commit("CHANGE_AUTH_OPTION", "login");
    },
    passwordChecker(password) {
      const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      return re.test(password);
    },
    resetPassword() {
      this.$refs.ruleFormRef.validate((valid) => {
        console.log(valid);
        if (!valid) return;

        // const isValidPass = this.passwordChecker(this.ruleForm.newPassword);

        // if (!isValidPass) {
        //   ElNotification({
        //     title: "Error",
        //     message: this.$t("password_constraint"),
        //     type: "error",
        //   });
        //   return;
        // }

        const data = {
          username: this.username,
          password: this.ruleForm.newPassword,
          password2: this.ruleForm.confirmNewPassword,
          resetToken: this.ruleForm.resetToken,
        };

        this.$store
          .dispatch("auth/resetPassword", data)
          .then(() => {
            this.$store.commit("CHANGE_AUTH_OPTION", "login");
          })
          .catch((err) => {
            ElNotification({
              title: "Error",
              message: this.$t(err.response.data.message),
              type: "error",
            });
          });
      });
    },
  },
};
</script>
    
    <style scoped>
.new-password .return {
  cursor: pointer;
  width: fit-content;
  margin-bottom: 1rem;
}

.new-password p.back {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.3rem;
}

.new-password img {
  width: 1.5rem;
  vertical-align: middle;
}

.new-password p.create-account {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #262626;
  text-align: center;
  margin-top: 2rem;
}

.new-password p.info {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
  margin-top: 0.1rem;
  margin-bottom: 1rem;
  text-align: center;
}

.new-password p.label-username,
.new-password p.label-email,
.new-password p.label-reset-token {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  z-index: 1;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  transition: 0.3s;

  color: rgba(38, 38, 38, 0.4);
}

.new-password .el-form .el-input :deep(.el-input__wrapper) {
  border-radius: 12px;
  padding: 0.5rem 1rem;
}

.new-password .el-form :deep(.el-input .el-input__wrapper.is-focus) {
  border: 1px solid #0e74bd;
  box-shadow: 0px 0px 0px 4px rgba(14, 116, 189, 0.15);
}

.new-password p.agree {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #484a4e;
}

.new-password p.agree span {
  color: #0093e9;
  text-decoration: underline;
}

.new-password .el-button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #262626;
  width: 100%;
  padding: 1.8rem 1rem;
  margin-top: 1rem;
  background: linear-gradient(
      83.9deg,
      #c3f7c4 1.51%,
      rgba(195, 247, 196, 0) 100%
    ),
    #0093e9;
  border-radius: 40px;
}
</style>