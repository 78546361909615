<template>
  <section class="terms-conditions">
    <base-container>
      <div class="card">
        <template v-for="content in contents" :key="content.id">
          <el-row
            :gutter="1"
            v-if="content.slug === 'static-page-terms-and-conditions'"
          >
            <el-col :sm="24" :md="10">
              <base-card>
                <h2>
                  {{ content.title }}
                </h2>
                <img src="../assets/img.png" alt="" />
              </base-card>
            </el-col>
            <el-col :sm="24" :md="14">
              <base-card>
                <div v-html="content.content"></div>
              </base-card>
            </el-col>
          </el-row>
        </template>
      </div>
    </base-container>
  </section>
</template>

<script>
export default {
  computed: {
    contents() {
      return this.$store.getters["dashboard/contents"];
    },
  },
};
</script>
    
<style scoped>
.terms-conditions {
  background: #f3f3f5;
}

.terms-conditions .card {
  background: #262626;
  min-height: 100vh;
  padding: 1px;
}

/* .terms-conditions .card .el-row .el-col {
      padding: 4rem 2rem;
    } */

.terms-conditions .card .el-row .el-col:first-of-type .card {
  background: #d5f7e8;
  border-radius: 24px;
  padding: 4rem 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.terms-conditions .card .el-row .el-col:last-of-type .card {
  background: #fafafa;
  border-radius: 24px;
  padding: 4rem 2rem;
}

.terms-conditions .card h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  line-height: 64px;
  letter-spacing: -0.02em;
  color: #262626;
}

.terms-conditions .card img {
  width: 100%;
}

.terms-conditions .card p.heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #202226;
  margin-bottom: 1rem;
}

.terms-conditions .card p.description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #202226;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 991px) {
  .terms-conditions .card h2 {
    font-size: 55px;
  }

  .terms-conditions .card .el-row .el-col:first-of-type .card {
    padding: 4rem 1rem;
  }

  .terms-conditions .card .el-row .el-col:last-of-type .card {
    padding: 4rem 1rem;
  }
}
</style>