export default {
  LOGIN(state, payload) {
    state.currentUserDetails = payload;
    state.isLoggedIn = true;
    state.active = true;
  },
  LOGOUT(state) {
    state.isLoggedIn = false;
    state.active = true;
  },
  AUTO_LOGOUT(state) {
    state.active = false;
  },
};
