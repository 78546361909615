<template>
  <link rel="stylesheet" type="text/css" :href="userStyle" />
  <div
    v-if="isDataLoaded"
    :class="`${isTheme ? `theme-${this.userDetails.resources.theme.id}` : ''}`"
  >
    <!-- <template v-if="isDataLoaded"> -->
    <!-- <div :class="`theme-${this.userDetails.resources.theme.id}`"> -->
    <section class="home-user bg">
      <!-- <section class="home-user" :class="`bg-${userDetails.resources.theme.id}`"> -->
      <div class="wrapper">
        <img
          v-if="hideImages"
          class="left-dot"
          src="../assets/digital-dot-left@2x.jpg"
          alt=""
        />
        <img
          v-if="hideImages"
          class="right-dot"
          src="../assets/digital-dot-right@2x.jpg"
          alt=""
        />
        <base-container>
          <!-- <div :class="`bg-${userDetails.resources.theme.id}`" class="inner-bg"> -->
          <div class="inner-bg">
            <!-- <img
            @click="exportData"
            src="../assets/share.png"
            class="share-img"
            alt=""
          /> -->
            <div class="content">
              <img class="avatar" :src="userDetails.item.avatar" alt="" />
              <p class="profile-name">{{ userDetails.item.displayName }}</p>
              <p class="profile-desc">
                {{ userDetails.item.description }}
              </p>
              <!-- <p class="profile-desc">
          Lorem ipsum dolor sit amet consectetur Tincidunt duis eros turpis
          facilisis sit
        </p> -->
              <div class="icons">
                <!-- <img
            @click="redirect(social.href)"
            v-for="social in userDetails.resources.social"
            :key="social.id"
            src="../assets/display-email-off@2x.png"
            alt=""
          /> -->
                <template
                  v-for="social in userDetails.resources.social"
                  :key="social.id"
                >
                  <a
                    :href="social.href"
                    v-if="social.content && social.media === 'Email'"
                  >
                    <img src="../assets/display-email-off@2x.png" alt="" />
                  </a>
                  <!-- <a
                  :href="social.href"
                  v-if="social.content && social.media === 'Phone'"
                >
                  <img src="../assets/display-phone-off@2x.png" alt="" />
                </a> -->
                  <a
                    :href="social.href"
                    v-if="social.content && social.media === 'Facebook'"
                  >
                    <img
                      v-if="social.media === 'Facebook'"
                      @click="redirect(social.href)"
                      src="../assets/display-facebook-off@2x.png"
                      alt=""
                    />
                  </a>
                  <a
                    :href="social.href"
                    v-if="social.content && social.media === 'Instagram'"
                  >
                    <img src="../assets/display-instagram-off@2x.png" alt="" />
                  </a>
                  <a
                    :href="social.href"
                    v-if="social.content && social.media === 'Youtube'"
                  >
                    <img src="../assets/display-youtube-off@2x.png" alt="" />
                  </a>
                  <a
                    :href="social.href"
                    v-if="social.content && social.media === 'WhatsApp'"
                  >
                    <img src="../assets/display-whatsapp-off.png" alt="" />
                  </a>
                  <a
                    :href="social.href"
                    v-if="social.content && social.media === 'PayMe'"
                  >
                    <img src="../assets/display-payme-off.png" alt="" />
                  </a>
                </template>
              </div>
              <Content />
            </div>
          </div>
        </base-container>
      </div>
    </section>
    <!-- </template> -->
  </div>
  <!-- <div class="black-screen" v-if="!isDataLoaded"></div> -->
</template>

<script>
import Content from "@/components/home-user/Content.vue";
// import { ElNotification } from "element-plus";
// import { excelParser } from "@/exportfromjson";
import { sampleData } from "@/sample-data";

export default {
  components: {
    Content,
  },
  data() {
    return {
      listData: sampleData,
      hideImages: false,
      isDataLoaded: false,
    };
  },
  computed: {
    currentUserDetails() {
      return this.$store.getters["auth/currentUserDetails"];
    },
    userDetails() {
      return this.$store.getters["profile/userDetails"];
    },
    origin() {
      return window.location.origin;
    },
    userStyle() {
      return `/api/v1/accounts/${this.userDetails.item.username}/styles.css`;
    },
    isTheme() {
      return "theme" in this.userDetails.resources;
    },
    themeStyle() {
      return `bg-${this.userDetails.resources.theme.id}`;
    },
  },
  methods: {
    redirect(path) {
      console.log(path);
      console.log(`${this.origin}${path}`);
      window.location.href = `${this.origin}${path}`;
    },
    exportData() {
      window.location.href = `${this.origin}/api/v1/accounts/${this.userDetails.item.username}/card.vcf`;
    },
  },
  // mounted() {
  //   const bg = document.querySelector(".home-user");

  //   if (this.isTheme) {
  //     console.log(bg);
  //   }
  // },
  created() {
    console.log("homeuser");
    document.body.style.backgroundColor = "black";
    this.$store
      .dispatch("profile/getUserDetails", this.$route.params.user)
      .then(() => {
        this.isDataLoaded = true;
      })
      .catch(() => {
        this.$router.replace({
          name: "home",
          query: { dialog: "show-dialog", referral: this.$route.params.user },
        });
      });
  },
};
</script>

<style scoped>
.home-user {
  /* background: #9fcdc2; */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  width: 100%;
}

.black-screen {
  width: 100%;
  height: 100vh;
  background: black;
}

.home-user.is-loading {
  background: black;
}
.wrapper.is-loading {
  display: none;
}

.home-user .container {
  padding-top: 3rem;
  padding-bottom: 3rem;
  position: relative;
}

.home-user .inner-bg {
  padding: 2rem;
  border-radius: 16px;
}

.home-user img.share-img {
  width: 3rem;
  position: absolute;
  right: 3rem;
  top: 4rem;
  cursor: pointer;
  z-index: 2;
}

.home-user img.left-dot,
.home-user img.right-dot {
  position: absolute;
  width: 13.5rem;
}

.home-user img.right-dot {
  right: 0;
}

.home-user img.left-dot {
  left: 0;
}

.home-user .content {
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30rem;
}

.home-user img.avatar {
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 17px 24px rgba(60, 119, 104, 0.24));
  border-radius: 120px;
  width: 7rem;
  height: 7rem;
}

.home-user p.profile-name {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* color: #ffffff; */
  text-align: center;
  margin-top: 1rem;
}

.home-user p.profile-desc {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  /* color: #ffffff; */
  margin: 0.5rem 0;
  white-space: pre-line;
}

.home-user p.profile-name,
.home-user p.profile-desc {
  /* border: none;
  padding: 0;
  background-color: transparent; */
  color: inherit;
}

.home-user .icons {
  display: flex;
  gap: 1rem;
}

/* .home-user .icons a {
  margin-right: 1rem;
} */

.home-user .icons img {
  width: 2.7rem;
  border: 1px solid white;
  border-radius: 100%;
  padding: 0.3rem;
  margin: 1rem 0 1.5rem 0;
  cursor: pointer;
}

.home-user .icons img:not(:last-of-type) {
  margin-right: 0.7rem;
}

@media screen and (max-width: 991px) {
  .home-user .content {
    width: 21rem;
  }
}
</style>