<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <section class="copyright">
    <base-card>
      <el-row>
        <el-col :sm="24" :md="2">
          <img src="../../assets/brand-logo@2x.png" alt="" />
        </el-col>
        <el-col :sm="24" :md="22">
          <div class="links">
            <span @click="$router.push('/about-us')">{{ $t("about_us") }}</span>
            <span @click="$router.push('/contact-us')">{{
              $t("contact_us")
            }}</span>
            <span @click="$router.push('/privacy-policy')">{{
              $t("privacy_policy")
            }}</span>
          </div>
          <p class="text">
            {{ $t("about_the_company") }}
          </p>
          <p class="copyright-text">
            Copyright © {{ year }} One Tap International Limited. All Rights
            Reserved
          </p>
          <!-- <p class="copyright-text">
            {{ $t("copyright") }}
          </p> -->
        </el-col>
      </el-row>
    </base-card>
  </section>
</template>

<script>
export default {
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>
.copyright {
  height: 100%;
}
.copyright img {
  width: 3.5rem;
}

.copyright .card {
  background: #f3f3f5;
  height: 100%;
}

.copyright .links {
  display: flex;
}

.copyright .links span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #262626;
  cursor: pointer;
}

.copyright .links span:nth-of-type(2) {
  margin: 0 3rem;
}

.copyright p.text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(33, 34, 38, 0.8);
  margin: 1rem 0;
}

.copyright p.copyright-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(33, 34, 38, 0.8);
}

@media screen and (max-width: 400px) {
  .copyright .links {
    flex-direction: column;
  }

  .copyright .links span:nth-of-type(2) {
    margin: 1rem 0;
  }
}
</style>