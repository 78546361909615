export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chinese"])},
  "one_tap_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一键式数字名片"])},
  "protect_our_planet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保护我们的星球从你开始"])},
  "one_tap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一键数字名片链接到您所有的社交媒体、视频、文章、网站、内容和新发布"])},
  "get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始"])},
  "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特征"])},
  "easy_to_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用方便"])},
  "easy_to_use_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加社交媒体、视频、文章、网站、内容和新发布"])},
  "social_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接到您的社交网站"])},
  "social_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的关注者可以在其他平台上找到您"])},
  "web_based": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基于网络"])},
  "web_based_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无需安装任何东西"])},
  "protecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保护"])},
  "our_planet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的星球"])},
  "starts_with_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从你开始"])},
  "error.account.invalid-username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无效的用户名！"])},
  "error.account.invalid-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无效的密码！"])},
  "products_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对我们的产品和服务有疑问？"])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
  "save_the_planet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拯救地球"])},
  "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
  "terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条款和条件"])},
  "about_the_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["介绍一下“One Tap电子名片”。One Tap是一种具有革命性意义的工具，旨在实现无缝网路交流。这个创新、便利的工具取代了传统的纸本名片，提供了更为高科技、环保的社交模式。只需简单轻触一下，您就可以轻松交换联络资讯并建立有价值的网络。One Tap透过连结和消除对实体名片的需求，简化使用者人际网路的发展，同时为绿色未来做出了贡献。扩大您的人际网路，用一键式名片给人留下深刻的印象。"])},
  "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["版权所有 © 2022 One Tap International Limited。版权所有"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎"])},
  "login_to_one_tap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录您的 ONE TAP 帐户"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
  "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘记密码"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
  "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报名"])},
  "remember_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记住账号"])},
  "dont_have_an_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有帐户？"])},
  "username_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名是必需的！"])},
  "password_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码是必需的！"])},
  "enter_username_and_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的用户名和电子邮件地址"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
  "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重设密码"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后退"])},
  "create_acccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建您的帐户"])},
  "enter_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请在下方输入您的信息"])},
  "display_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示名称"])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码"])},
  "terms_and_conditions_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建帐户即表示您同意我们的"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登记"])},
  "enter_password_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请再次输入密码。"])},
  "passwords_do_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码不匹配！"])},
  "name_is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名为必填项！"])},
  "email_is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要邮箱！"])},
  "enter_valid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效电子邮件。"])},
  "password_constraint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码必须至少8个字符，且至少包含1个大写字母、1个小写字母、1个数字和1个特殊字符"])},
  "personal_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人信息"])},
  "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户类型"])},
  "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人的"])},
  "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商业"])},
  "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["头像"])},
  "choose_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择照片"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "update_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
  "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改密码"])},
  "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旧密码"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码"])},
  "confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认新密码"])},
  "change_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["改变"])},
  "phone_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要电话！"])},
  "token_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["令牌已过期！请重新登录。"])},
  "information_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信息更新！"])},
  "new_password_is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要新密码！"])},
  "enter_new_password_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请再次输入新密码。"])},
  "old_password_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要旧密码！"])},
  "password_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码已更新"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["轮廓"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内容"])},
  "styling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["造型"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制"])},
  "social_profile_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社交资料链接"])},
  "phone_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的电话号码"])},
  "views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["观点"])},
  "whatsapp_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预写的 Whatsapp 消息"])},
  "add_new_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加新内容"])},
  "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关联"])},
  "add_link_to_one_tap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将链接添加到您的一键式"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
  "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["照片"])},
  "add_photo_to_one_Tap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一键添加照片"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["视频"])},
  "add_video_to_one_Tap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一键添加视频"])},
  "upload_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击上传"])},
  "theme_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主题图书馆"])},
  "create_your_own": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创造你的自己的"])},
  "custom_theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义主题"])},
  "customize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择您自定义的按钮、颜色、字体和背景"])},
  "buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钮扣"])},
  "button_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按钮尺寸"])},
  "button_font_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按钮字体颜色"])},
  "button_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按钮颜色"])},
  "font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字体"])},
  "font_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字体颜色"])},
  "flat_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["纯色"])},
  "gradient_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["渐变色"])},
  "upload_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传图片"])},
  "content_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加内容"])},
  "first_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第一种颜色"])},
  "second_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第二种颜色"])},
  "share_your_theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享你的主题"])},
  "share_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将您的客户主题分享到公共图书馆"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享"])},
  "icon_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图标库"])},
  "icon_library_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改社交媒体、社交链接或其他网站链接图标"])},
  "image_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["( PNG/GIF 格式)"])},
  "my_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的链接"])},
  "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未注册帐号"])},
  "theme_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主题更新"])},
  "icon_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图标已更新"])},
  "url_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网址已复制"])},
  "update_theme_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择按钮、字体和背景样式"])},
  "select_theme_to_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择要分享的主题"])},
  "theme_shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主题共享"])},
  "create_theme_to_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请创建一个主题来分享"])},
  "select_create_your_own": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请先从主题库中选择创建您自己的主题"])},
  "theme_library_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义您的按钮、颜色、字体和背景"])},
  "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
  "confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按确认按钮分享"])},
  "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "share_canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享已取消"])},
  "fill_all_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写所有字"])},
  "reset_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置令牌"])},
  "reset_token_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要重置令牌"])},
  "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私权政策"])}
}