<template>
  <div class="icon-library-dialog">
    <el-dialog
      :model-value="dialogVisible"
      width="60%"
      @close="$emit('closedDialog', false)"
    >
      <div class="head">
        <h1>{{ $t("icon_library") }}</h1>
        <img
          @click="$emit('closedDialog', false)"
          src="../../assets/Close.png"
          alt=""
        />
      </div>
      <p>{{ $t("icon_library_description") }}</p>
      <div class="box">
        <el-upload
          class="avatar-uploader"
          :on-success="handleSuccess"
          :action="`${origin}/api/v1/system/uploads`"
          :show-file-list="false"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <!-- <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon> -->
          <div v-else class="img-content">
            <img class="img-icon" src="../../assets/icon-1.png" alt="" />
            <p>{{ $t("upload_image") }}</p>
            <p>{{ $t("image_format") }}</p>
          </div>
        </el-upload>
        <el-row :gutter="20">
          <el-col :sm="12" :md="4" v-for="icon in icons" :key="icon.id">
            <div
              @click="setOption(icon)"
              :class="{ 'is-selected': currentIcon === icon.id }"
              class="inner-box"
            >
              <img :src="icon.href" alt="" />
              <!-- <img src="../../assets/profile-photo@2x.png" alt="" /> -->
            </div>
          </el-col>
          <el-col :xs="12" :md="4" v-if="userImg">
            <div
              class="inner-box"
              @click="setOption('user-icon')"
              :class="{ 'is-selected': currentIcon === 'user-icon' }"
            >
              <img :src="userImg" />
            </div>
          </el-col>
        </el-row>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="icons.length + 0"
        />
      </div>
      <!-- <el-button class="update" @click="update">{{
        $t("update_button")
      }}</el-button> -->
    </el-dialog>
  </div>
</template>
  
  <script>
import { ElNotification } from "element-plus";

export default {
  props: ["dialogVisible", "iconId", "media"],
  data() {
    return {
      imageUrl: "",
      currentIcon: null,
      selectedIcon: null,
      userImg: "",
    };
  },
  computed: {
    selectedButtonDetails() {
      return this.$store.getters["profile/selectedButtonDetails"];
    },
    selectedButtonStyles() {
      return this.$store.getters["profile/selectedButtonStyles"];
    },
    selectedFontStyle() {
      return this.$store.getters["profile/selectedFontStyle"];
    },
    selectedBackgroundStyle() {
      return this.$store.getters["profile/selectedBackgroundStyle"];
    },
    userDetails() {
      return this.$store.getters["profile/userDetails"];
    },
    icons() {
      return this.$store.getters["profile/icons"];
    },
    origin() {
      return window.location.origin;
    }
  },
  methods: {
    setOption(icon) {
      if (icon === "user-icon") {
        this.currentIcon = icon;
        this.selectedIcon = icon;
        this.$emit("setIcon", this.userImg);
        console.log(this.userImg);
        return;
      }
      this.currentIcon = icon.id;
      console.log(icon);
      this.selectedIcon = icon.href;
      this.$emit("setIcon", icon.href);
    },
    handleSuccess(response, uploadFile) {
      console.log(response);
      console.log(uploadFile);
      this.userImg = `/api/v1/system/uploads/${response.item.name}`;
      // const image = `/api/v1/system/uploads/${response.item.name}`;
      // this.imageUrl = `/api/v1/system/uploads/${response.item.name}`;
      // this.imageUrl = `https://one-tap-membership.dev.marvelconnect.com/api/v1/system/uploads/${response.item.name}`;
      // console.log(this.imageUrl);
      // this.$store
      //   .dispatch("auth/checkAccessToken")
      //   .then(() => {
      //     this.$store.dispatch("profile/addIcon", { href: image }).then(() => {
      //       this.$store.dispatch("profile/getIcons");
      //     });
      //   })
      //   .catch(() => {
      //     this.$store
      //       .dispatch("auth/checkRefreshToken")
      //       .then(() => {
      //         this.$store
      //           .dispatch("profile/addIcon", { href: image })
      //           .then(() => {
      //             this.$store.dispatch("profile/getIcons");
      //           });
      //       })
      //       .catch(() => {
      //         ElNotification({
      //           title: "Error",
      //           message: this.$t("token_expired"),
      //           type: "error",
      //         });
      //         this.$store.dispatch("auth/logout");
      //       });
      //   });
    },
    update() {
      const data = {
        id: this.iconId,
        data: {
          account: this.userDetails.item.id,
          media: this.media,
          icon: this.selectedIcon,
        },
      };
      console.log(data);

      this.$store
        .dispatch("auth/checkAccessToken")
        .then(() => {
          this.$store.dispatch("profile/updateSocialIcon", data).then(() => {
            ElNotification({
              title: "Success",
              message: this.$t("icon_updated"),
              type: "success",
            });
            this.$emit("closedDialog", false);
          });
        })
        .catch(() => {
          this.$store
            .dispatch("auth/checkRefreshToken")
            .then(() => {
              this.$store
                .dispatch("profile/updateSocialIcon", data)
                .then(() => {
                  ElNotification({
                    title: "Success",
                    message: this.$t("icon_updated"),
                    type: "success",
                  });
                  this.$emit("closedDialog", false);
                });
            })
            .catch(() => {
              ElNotification({
                title: "Error",
                message: this.$t("token_expired"),
                type: "error",
              });
              this.$store.dispatch("auth/logout");
            });
        });
    },
  },
};
</script>
  
  <style scoped>
.icon-library-dialog {
  margin-top: 2rem;
}

.icon-library-dialog :deep(.el-dialog) {
  background: #f3f3f5;
  border: 1px solid #000000;
  border-radius: 24px;
}

.icon-library-dialog :deep(.el-dialog__header) {
  display: none;
}

.icon-library-dialog .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-library-dialog .head img {
  width: 1.5rem;
  cursor: pointer;
}

.icon-library-dialog h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: #262626;
}

.icon-library-dialog p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  margin-top: 0.5rem;
}

.icon-library-dialog .box {
  background: #f3f3f5;
  border: 1px solid #000000;
  border-radius: 24px;
  padding: 1rem;
  margin-top: 2rem;
}

.icon-library-dialog .img-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-library-dialog .img-icon {
  width: 2rem;
}

.icon-library-dialog :deep(.el-upload.el-upload--text) {
  width: 100%;
  height: 10rem;
}

.icon-library-dialog .img-content p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #202226;
}

.icon-library-dialog .img-content p:last-of-type {
  font-size: 14px;
  line-height: 20px;
  color: rgba(32, 34, 38, 0.4);
  margin-top: 0.5rem;
}

.icon-library-dialog .inner-box {
  background: #e9e9eb;
  border: 1px solid #262626;
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8rem;
  cursor: pointer;
  margin-bottom: 1rem;
}
.icon-library-dialog .inner-box.is-selected {
  background: rgba(0, 147, 233, 0.1);
  border: 2px solid #0093e9;
  border-radius: 12px;
}

.icon-library-dialog .inner-box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.icon-library-dialog .el-row {
  margin-top: 2rem;
}

.icon-library-dialog .el-row .el-col {
  margin-bottom: 1rem;
}

.icon-library-dialog .el-pagination {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.icon-library-dialog .el-pagination :deep(.number) {
  border: 1px solid #262626;
  border-radius: 12px;
}

.icon-library-dialog .el-pagination :deep(.number.is-active) {
  border: none;
}

.icon-library-dialog .el-button.update {
  background: #0093e9;
  border-radius: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  display: flex;
  margin-left: auto;
  padding: 1.5rem 3rem;
  margin-top: 2rem;
}

@media screen and (max-width: 767px) {
  .icon-library-dialog .inner-box img {
    height: 100%;
    object-fit: cover;
  }
}
@media screen and (max-width: 650px) {
  .icon-library-dialog :deep(.el-dialog) {
    width: 90%;
  }
}
</style>
  
  <style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>