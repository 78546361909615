export default {
  SET_USER_DETAILS(state, payload) {
    state.userDetails = payload;
  },
  SET_ACCOUNT_SOCIALS(state, payload) {
    state.accountSocials = payload;
  },
  SET_BUTTONS(state, payload) {
    state.buttons = payload;
  },
  SET_FONTS(state, payload) {
    state.fonts = payload;
  },
  SET_SELECTED_BUTTON_DETAILS(state, payload) {
    state.selectedButtonDetails = payload;
  },
  SET_SELECTED_BUTTON_STYLES(state, payload) {
    state.selectedButtonStyles = payload;
  },
  SET_SELECTED_FONT_STYLE(state, payload) {
    state.selectedFontStyle = payload;
  },
  SET_SELECTED_BACKGROUND_STYLE(state, payload) {
    state.selectedBackgroundStyle = payload;
  },
  SET_ICONS(state, payload) {
    state.icons = payload;
  },
  SET_THEMES(state, payload) {
    state.themes = payload;
    console.log(state.themes);
  },
  SET_SELECTED_THEME(state, payload) {
    state.selectedTheme = payload;
  },
  RESET_CUSTOM_THEME(state) {
    state.selectedButtonDetails = {};
    state.selectedButtonStyles = {};
    state.selectedFontStyle = {};
    state.selectedBackgroundStyle = {};
    state.selectedTheme = {};
  },
  SET_USERS(state, payload) {
    state.users = payload;
  },
};
