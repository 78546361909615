<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<style scoped>
.card {
  padding: 2.5rem 2.5rem;
  border-radius: 24px;
}
</style>