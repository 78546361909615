import axios from "axios";
import i18n from "@/i18n";

export default {
  async getUserDetails(context, payload) {
    // const userToken = localStorage.getItem("accessToken");

    const response = await axios.get(`/api/v1/accounts/${payload}`, {
      headers: {
        // Authorization: `Bearer ${userToken}`,
        "accept-language": i18n.global.locale,
      },
    });
    context.commit("SET_USER_DETAILS", response.data);
  },
  async updateUserDetails(_, payload) {
    const userToken = localStorage.getItem("accessToken");

    const response = await axios.put(
      `/api/v1/accounts/${payload.id}`,
      payload.data,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "accept-language": i18n.global.locale,
        },
      }
    );
    console.log(response);
  },
  // async addNewContent(_, payload) {
  //   const userToken = localStorage.getItem("accessToken");

  //   await axios.post("/api/v1/accounts/contents", payload, {
  //     headers: {
  //       Authorization: `Bearer ${userToken}`,
  //       "accept-language": i18n.global.locale,
  //     },
  //   });
  // },
  async updateUserAvatar(_, payload) {
    const userToken = localStorage.getItem("accessToken");

    const response = await axios.put(
      `/api/v1/accounts/${payload.id}`,
      { avatar: payload.avatar },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "accept-language": i18n.global.locale,
        },
      }
    );
    console.log(response);
  },
  async getAccountSocials(context) {
    const userToken = localStorage.getItem("accessToken");

    const response = await axios.get("/api/v1/accounts/socials", {
      headers: {
        Authorization: `Bearer ${userToken}`,
        "accept-language": i18n.global.locale,
      },
    });
    context.commit("SET_ACCOUNT_SOCIALS", response.data.items);
  },

  async updateAccountSocials(_, payload) {
    const userToken = localStorage.getItem("accessToken");

    const response = await axios.put(
      `/api/v1/accounts/socials/${payload.id}`,
      payload.data,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "accept-language": i18n.global.locale,
        },
      }
    );
    console.log(response);
  },
  async getButtons(context) {
    const userToken = localStorage.getItem("accessToken");

    const response = await axios.get("/api/v1/libraries/buttons", {
      headers: {
        Authorization: `Bearer ${userToken}`,
        "accept-language": i18n.global.locale,
      },
    });
    console.log(response);
    context.commit("SET_BUTTONS", response.data.items);
  },
  async getThemes(context, payload) {
    // const userToken = localStorage.getItem("accessToken");

    const response = await axios.get("/api/v1/libraries/themes", {
      headers: {
        // Authorization: `Bearer ${userToken}`,
        "accept-language": i18n.global.locale,
      },
      params: {
        page: payload,
        pageSize: 11,
      },
    });
    context.commit("SET_THEMES", response.data);
  },
  async getFonts(context) {
    const userToken = localStorage.getItem("accessToken");

    const response = await axios.get("/api/v1/libraries/fonts", {
      headers: {
        Authorization: `Bearer ${userToken}`,
        "accept-language": i18n.global.locale,
      },
    });
    context.commit("SET_FONTS", response.data.items);
  },
  async share(_, payload) {
    const userToken = localStorage.getItem("accessToken");

    const response = await axios.post(
      "/api/v1/libraries/themes/@me/share",
      payload,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "accept-language": i18n.global.locale,
        },
      }
    );
    console.log(response);
  },
  async updateTheme(_, payload) {
    const userToken = localStorage.getItem("accessToken");

    const response = await axios.put("/api/v1/libraries/themes/@me", payload, {
      headers: {
        Authorization: `Bearer ${userToken}`,
        "accept-language": i18n.global.locale,
      },
    });
    console.log(response);
  },
  async getIcons(context) {
    const userToken = localStorage.getItem("accessToken");

    const response = await axios.get("/api/v1/libraries/icons", {
      headers: {
        Authorization: `Bearer ${userToken}`,
        "accept-language": i18n.global.locale,
      },
    });
    context.commit("SET_ICONS", response.data.items);
  },
  async getAccounts(context) {
    const userToken = localStorage.getItem("accessToken");

    const response = await axios.get("/api/v1/accounts", {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    context.commit("SET_USERS", response.data.items);
    console.log(response);
  },

  async addIcon(_, payload) {
    const userToken = localStorage.getItem("accessToken");

    const response = await axios.post("/api/v1/libraries/icons", payload, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    console.log(response);
  },
  async updateSocialIcon(_, payload) {
    const userToken = localStorage.getItem("accessToken");

    const response = await axios.put(
      `/api/v1/accounts/socials/${payload.id}`,
      payload.data,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    console.log(response);
  },
};
