<template>
  <section class="user-profile" v-loading.fullscreen.lock="fullscreenLoading">
    <base-container>
      <div class="top-section">
        <div
          :class="{ 'is-active': $route.name === 'profile' }"
          @click="navigate('profile')"
          class="pill"
        >
          <img :src="profileImg" alt="" />
          <p>{{ $t("profile") }}</p>
        </div>
        <div
          :class="{ 'is-active': $route.name === 'content' }"
          @click="navigate('content')"
          class="pill"
        >
          <img :src="contentImg" alt="" />
          <p>{{ $t("content") }}</p>
        </div>
        <div
          :class="{ 'is-active': $route.name === 'styling' }"
          @click="navigate('styling')"
          class="pill"
        >
          <img :src="stylingImg" alt="" />
          <p>{{ $t("styling") }}</p>
        </div>
        <div @click="logout" class="pill">
          <img src="../assets/logout-on-off@2x.png" alt="" />
          <p>{{ $t("logout") }}</p>
        </div>
        <div class="pill">
          <div class="text">
            <p>{{ $t("my_link") }}</p>
            <a
              :href="`${origin}/${userDetails.username}`"
              target="_blank"
              >{{ `${origin}/${userDetails.username}` }}</a
            >
            <!-- <p>{{ `${origin}/${userDetails.item.username}` }}</p> -->
            <!-- <p>{{ `https://www.${userDetails.item.username}.com` }}</p> -->
          </div>
          <el-button @click="copyMe">{{ $t("copy") }}</el-button>
        </div>
      </div>
      <router-view></router-view>
      <!-- <personal-information></personal-information>
      <change-password></change-password> -->
    </base-container>
  </section>
</template>

<script>
import { ElNotification } from "element-plus";
export default {
  data() {
    return {
      profileImg: require("../assets/profile-on-off@2x.png"),
      contentImg: require("../assets/content-off@2x.png"),
      stylingImg: require("../assets/styling-off-5.png"),
      fullscreenLoading: false,
    };
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.$route.name === "profile"
          ? (this.profileImg = require("../assets/profile-on@2x.png"))
          : (this.profileImg = require("../assets/profile-on-off@2x.png"));

        this.$route.name === "content"
          ? (this.contentImg = require("../assets/content-on@2x.png"))
          : (this.contentImg = require("../assets/content-off@2x.png"));

        this.$route.name === "styling"
          ? (this.stylingImg = require("../assets/styling-off-white.png"))
          : (this.stylingImg = require("../assets/styling-off-5.png"));
      },
    },
  },
  computed: {
    userDetails() {
      return this.users.find(
        (user) => user.username === this.$route.params.user
      );
    },
    // userDetails() {
    //   return this.$store.getters["profile/userDetails"];
    // },
    users() {
      return this.$store.getters["profile/users"];
    },
    origin() {
      return window.location.origin;
    },
    // active() {
    //   return this.$store.getters["auth/active"];
    // },
  },
  methods: {
    navigate(path) {
      this.$router.push({ name: path });
    },
    copyMe() {
      navigator.clipboard
        .writeText(
          `${this.origin}/${this.userDetails.username}`
          // `${this.origin}/${this.userDetails.item.username}`
        )
        .then(() => {
          ElNotification({
            title: "Success",
            message: this.$t("url_copied"),
            type: "success",
          });
        });
    },
    logout() {
      this.$store
        .dispatch("auth/checkAccessToken")
        .then(() => {
          this.$store.dispatch("auth/logout");
        })
        .catch(() => {
          this.$store
            .dispatch("auth/checkRefreshToken")
            .then(() => {
              this.$store.dispatch("auth/logout");
            })
            .catch(() => {
              ElNotification({
                title: "Error",
                message: this.$t("token_expired"),
                type: "error",
              });
              this.$store.dispatch("auth/logout");
            });
        });
    },
  },
  created() {
    // if (!this.active) {
    //   ElNotification({
    //     title: "Error",
    //     message: "Please Login First!",
    //     type: "error",
    //   });
    //   this.$store.replace("/");
    //   return;
    // }
    this.$store
      .dispatch("auth/checkAccessToken")
      .then(() => {
        this.$store.dispatch("profile/getAccounts");
        this.$store.dispatch("profile/getIcons");
      })
      .catch(() => {
        this.$store
          .dispatch("auth/checkRefreshToken")
          .then(() => {
            this.$store.dispatch("profile/getAccounts");

            this.$store.dispatch("profile/getIcons");
          })
          .catch(() => {
            ElNotification({
              title: "Error",
              message: "Token expired! Please login again.",
              type: "error",
            });
            this.$store.dispatch("auth/logout");
          });
      });
    this.$route.name === "profile"
      ? (this.profileImg = require("../assets/profile-on@2x.png"))
      : (this.profileImg = require("../assets/profile-on-off@2x.png"));

    this.$route.name === "content"
      ? (this.contentImg = require("../assets/content-on@2x.png"))
      : (this.contentImg = require("../assets/content-off@2x.png"));

    this.$route.name === "styling"
      ? (this.stylingImg = require("../assets/styling-off-white.png"))
      : (this.stylingImg = require("../assets/styling-off-5.png"));
  },
};
</script>

<style scoped>
.user-profile {
  background: #f3f3f5;
  border-top: 1px solid #000000;
  min-height: 100vh;
}

.user-profile .container {
  padding-right: 15rem;
  padding-left: 15rem;
}

.user-profile .top-section {
  display: flex;
  gap: 0.5rem;
}

.user-profile .top-section .pill {
  background: #f3f3f5;
  border: 1px solid #000000;
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11rem;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
}

.user-profile .top-section .pill.is-active {
  background: #0093e9;
  border-color: #0093e9;
}

.user-profile .top-section .pill:last-of-type {
  width: 100%;
  justify-content: space-between;
}

.user-profile .top-section .pill img {
  width: 1.8rem;
}

.user-profile .top-section .pill p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #262626;
}

.user-profile .top-section .pill.is-active p {
  color: #fff;
}

.user-profile .pill .text p:first-of-type {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #262626;
}

.user-profile .pill .text p:nth-of-type(2),
.user-profile .pill .text a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0093e9;
  word-break: break-all;
  text-decoration: none;
}

.user-profile .pill .el-button {
  background: #f3f3f5;
  border: 1px solid #000000;
  border-radius: 80px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #262626;
}

@media screen and (min-width: 1141px) and (max-width: 1329px) {
  .user-profile .top-section .pill:last-of-type {
    flex-direction: column;
  }
}

@media screen and (max-width: 1390px) {
  .user-profile .container {
    padding-right: 2rem;
    padding-left: 3rem;
  }

  .user-profile .top-section {
    flex-wrap: wrap;
  }
}
/* @media screen and (max-width: 1140px) {
  .user-profile .container {
    padding-right: 2rem;
    padding-left: 3rem;
  }

  .user-profile .top-section {
    flex-wrap: wrap;
  }
} */

@media screen and (max-width: 439px) {
  .user-profile .top-section .pill {
    width: 100%;
  }
}
</style>