export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chinese"])},
  "one_tap_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一鍵式數字名片"])},
  "protect_our_planet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保護我們的星球從你開始"])},
  "one_tap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一鍵數字名片鏈接到您所有的社交媒體、視頻、文章、網站、內容和新發布"])},
  "get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始"])},
  "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特徵"])},
  "easy_to_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用方便"])},
  "easy_to_use_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加社交媒體、視頻、文章、網站、內容和新發布"])},
  "social_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鏈接到您的社交網站"])},
  "social_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的關注者可以在其他平台上找到您"])},
  "web_based": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基於網絡"])},
  "web_based_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無需安裝任何東西"])},
  "protecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保護"])},
  "our_planet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們的星球"])},
  "starts_with_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["從你開始"])},
  "error.account.invalid-username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無效的用戶名！"])},
  "error.account.invalid-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無效的密碼！"])},
  "products_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["對我們的產品和服務有疑問？"])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯繫我們"])},
  "save_the_planet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拯救地球"])},
  "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關於我們"])},
  "terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["條款和條件"])},
  "about_the_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["介紹一下“One Tap電子名片”。One Tap是一種具有革命性意義的工具，旨在實現無縫網路交流。這個創新、便利的工具取代了傳統的紙本名片，提供了更為高科技、環保的社交模式。只需簡單輕觸一下，您就可以輕鬆交換聯絡資訊並建立有價值的網絡。One Tap透過連結和消除對實體名片的需求，簡化使用者人際網路的發展，同時為綠色未來做出了貢獻。擴大您的人際網路，用一鍵式名片給人留下深刻的印象。"])},
  "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["版權所有 © 2022 One Tap International Limited。版權所有"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歡迎"])},
  "login_to_one_tap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登錄您的 ONE TAP 帳戶"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用戶名"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼"])},
  "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘記密碼"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登錄"])},
  "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["報名"])},
  "remember_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記住賬號"])},
  "dont_have_an_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有帳戶？"])},
  "username_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用戶名是必需的！"])},
  "password_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼是必需的！"])},
  "enter_username_and_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入您的用戶名和電子郵件地址"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子郵件"])},
  "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重設密碼"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後退"])},
  "create_acccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["創建您的帳戶"])},
  "enter_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請在下方輸入您的信息"])},
  "display_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示名稱"])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認密碼"])},
  "terms_and_conditions_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["創建帳戶即表示您同意我們的"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登記"])},
  "enter_password_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請再次輸入密碼。"])},
  "passwords_do_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼不匹配！"])},
  "name_is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名為必填項！"])},
  "email_is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要郵箱！"])},
  "enter_valid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入有效電子郵件。"])},
  "password_constraint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼必須至少8個字符，且至少包含1個大寫字母、1個小寫字母、1個數字和1個特殊字符"])},
  "personal_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人信息"])},
  "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳戶類型"])},
  "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人的"])},
  "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商業"])},
  "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頭像"])},
  "choose_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇照片"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "update_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
  "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改密碼"])},
  "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["舊密碼"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密碼"])},
  "confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認新密碼"])},
  "change_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["改變"])},
  "phone_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要電話！"])},
  "token_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["令牌已過期！請重新登錄。"])},
  "information_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信息更新！"])},
  "new_password_is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要新密碼！"])},
  "enter_new_password_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請再次輸入新密碼。"])},
  "old_password_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要舊密碼！"])},
  "password_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼已更新"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輪廓"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["內容"])},
  "styling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["造型"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製"])},
  "social_profile_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社交資料鏈接"])},
  "phone_number_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的電話號碼"])},
  "views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["觀點"])},
  "whatsapp_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預寫的 Whatsapp 消息"])},
  "add_new_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加新內容"])},
  "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關聯"])},
  "add_link_to_one_tap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將鏈接添加到您的一鍵式"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
  "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["照片"])},
  "add_photo_to_one_Tap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一鍵添加照片"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["視頻"])},
  "add_video_to_one_Tap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一鍵添加視頻"])},
  "upload_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["點擊上傳"])},
  "theme_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主題圖書館"])},
  "create_your_own": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["創造你的自己的"])},
  "custom_theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定義主題"])},
  "customize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇您自定義的按鈕、顏色、字體和背景"])},
  "buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鈕扣"])},
  "button_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按鈕尺寸"])},
  "button_font_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按鈕字體顏色"])},
  "button_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按鈕顏色"])},
  "font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字體"])},
  "font_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字體顏色"])},
  "flat_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["純色"])},
  "gradient_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["漸變色"])},
  "upload_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳圖片"])},
  "content_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加內容"])},
  "first_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第一種顏色"])},
  "second_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第二種顏色"])},
  "share_your_theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享你的主題"])},
  "share_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將您的客戶主題分享到公共圖書館"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享"])},
  "icon_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圖標庫"])},
  "icon_library_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改社交媒體、社交鏈接或其他網站鏈接圖標"])},
  "image_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["( PNG/GIF 格式)"])},
  "my_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的鏈接"])},
  "no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未註冊帳號"])},
  "theme_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主題更新"])},
  "icon_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圖標已更新"])},
  "url_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["網址已復制"])},
  "update_theme_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇按鈕、字體和背景樣式"])},
  "select_theme_to_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇要分享的主題"])},
  "theme_shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主題共享"])},
  "create_theme_to_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請創建一個主題來分享"])},
  "select_create_your_own": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請先從主題庫中選擇創建您自己的主題"])},
  "theme_library_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定義您的按鈕、顏色、字體和背景"])},
  "confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])},
  "confirm_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按確認按鈕分享"])},
  "cancel_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "share_canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享已取消"])},
  "fill_all_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請填寫所有字"])},
  "reset_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置令牌"])},
  "reset_token_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要重置令牌"])},
  "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱私權政策"])}
}