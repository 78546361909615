import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      userDetails: [],
      accountSocials: [],
      buttons: [],
      fonts: [],
      selectedButtonDetails: {},
      selectedButtonStyles: {},
      selectedFontStyle: {},
      selectedBackgroundStyle: {},
      icons: [],
      themes: [],
      selectedTheme: {},
      users: [],
    };
  },
  getters,
  mutations,
  actions,
};
